body {
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;

	&.preload {
		overflow: hidden;
	}

	&.mfp-zoom-out-cur {
		overflow-y: hidden!important;
	}
}

html {
	margin-right: 0!important;
	height: auto!important;
}

a {
	@extend .animacja;
}

.animacja {
	@include transition(all, 0.23s, ease-in-out);
}

.big {
	text-transform: uppercase;
}

.low {
	text-transform: none!important;
}

.margin {
	&-0 {
		margin:0!important;
	}

	&-auto {
		margin:0 auto 3rem auto!important;
		display: block;
	}

	&-50 {
		margin:rem-calc(50) 0!important;
	}

	&-bottom0 {
		margin-bottom: 0!important;
	}

	&-bottom10 {
		margin: 0 0 rem-calc(10) 0!important;
	}

	&-bottom50 {
		margin: 0 0 rem-calc(50) 0!important;
	}

	&-bottom100 {
		margin: 0 0 rem-calc(100) 0!important;
	}
}

.padding {
	&-100 {
		padding: rem-calc(100) 0;

		&-small {
			@include breakpoint(medium down) {
				padding:0;
			}
		}
	}

	&-height {
		height: rem-calc(550);
		@include breakpoint(small only) {
			height: rem-calc(350);
		}
	}

	&-200 {
		padding: rem-calc(200) 0;
	}

	&-50 {
		padding: rem-calc(50) 0;
	}

	&-bottom50 {
		padding-bottom: rem-calc(50);
	}

	&-bottom100 {
		padding-bottom: rem-calc(100);
	}

	&-top100 {
		padding-top: rem-calc(100);
	}
}

.clear-ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.photobg {
	background-repeat: no-repeat;
	@include bgcover;
	background-position: center center;
	position: relative;
	z-index: 1;

	&-cover {
		&:before {
			@extend .before-absolute;
			background-color: rgba($black, 0.5);
	    box-shadow: inset 0 0 4rem rgba($black, 0.5);
		}
	}
}

.whitebg {
	background: $white;
}

.lightbg {
	background: $light;
}

.before-absolute {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}

.photobg-inner {
	position: relative;
	z-index: 10;

	* {
		color: $white;
	}
}

.display-table {
	display: table;
	table-layout: fixed;
	width: 100%;
	height: 100%;
}

.display-table-cell {
	display: table-cell;
	width: 100%;
	height: 100%;
	vertical-align: middle;
	position: relative;
	z-index: 50;

	&.v-bottom {
		vertical-align: bottom;
	}
}

.display-block {
	display: block;
	width: 100%;
}

.medium-block {
	@include breakpoint(medium down) {
		display: block!important;
		height: auto!important;
	}

	.display-table-cell {
		@include breakpoint(medium down) {
			display: block!important;
			height: auto!important;
		}
	}
}

.height100vh {
	height: 100vh;

	@include breakpoint(medium down) {
		height: auto!important;
		padding: rem-calc(150) 0!important;
	}
}

.line {
	width: 100%;
	height: rem-calc(1);
	background-color:lighten($black, 70%);
}

#preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 666;
	background: $white;

	#inner {
		display: block;
		width: 100%;
		height: 100%;
		text-align: center;
		background:url(../../dist/images/chill_logo_black.png) no-repeat center center;
	}
}

.broke-line {
	width: rem-calc(220);
	text-align: center;
	height: rem-calc(40);
	position: relative;

	&.margin-bottom {
		margin: rem-calc(30) auto rem-calc(30) auto;
	}

	&.margin-top {
		margin: rem-calc(0) auto rem-calc(30) auto;
	}

	&.black {
		&:before,
		&:after,
		span {
			background-color: $black;
		}
	}

	&:before,
	&:after {
		content: "";
		width: rem-calc(90);
		height:rem-calc(1);
		background: $white;
		position: absolute;
		top:rem-calc(19);
	}

	&:before {
		left: 0;
	}

	&:after {
		right: 0;
	}

	span {
		display: inline-block;
		width: rem-calc(40);
		height: rem-calc(1);
		background: $white;
		transform: rotate(-45deg);
		animation:broke-line-move 5s infinite;
	}
}

.more {
	border-top: rem-calc(2) solid $black;
}

.mfp-figure {
	figure {
		height: 100%!important;
		padding: 0!important;

		img {
			opacity: 1!important;
		}
	}
}

@keyframes broke-line-move {
  0%   { transform: rotate(-45deg); }
	50%   { transform: rotate(0deg); }
  100% { transform: rotate(-45deg); }
}

::selection {
    color: $white;
    background: $black;
}

@media only screen and (min-width: rem-calc(1025)) {
	[class*="column"] + [class*="column"]:last-child {
		float: left;
	}
}
