.photo-section-text {
  padding: rem-calc(100) rem-calc(50);
  background: $light;

  @include breakpoint(medium only) {
    padding: rem-calc(50);
  }

  @include breakpoint(small only) {
    padding: rem-calc(50) rem-calc(25);
  }
}

.photo-section {
  position: relative;

  @include breakpoint(medium down) {
    height: rem-calc(300);
  }

  h3 {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    margin: 0!important;
    padding: rem-calc(25);
    color: $white;
    font-size: rem-calc(30);
    background: $black;

    span {
      font-weight: 300;
      font-size: rem-calc(18);
      display: block;
    }
  }
}

#map {
  height: rem-calc(550);

  @include breakpoint(small only) {
    height: rem-calc(350);
  }
}

.arrow-scroll {
  display: block;
  width: rem-calc(60);
  line-height: rem-calc(60);
  text-align: center;
  background-color: $white;
  color: $black;
  position: absolute;
  z-index: 200;
  bottom: rem-calc(-1);
  left: 50%;
  transform: translateX(-50%);
  font-size: rem-calc(35);

  &:hover {
    background-color: $black;
    color: $white;
  }

  @include breakpoint(medium down) {
    display: none;
  }
}

.socialmedia {
  position: relative;
  z-index: 150;
  margin-top: rem-calc(-100);

  &-icon {
    width: rem-calc(55);
    line-height: rem-calc(55);
    background: $light;
    color: $black;
    display: inline-block;
    font-size: $large-size;
    margin: 0 rem-calc(10);

    &:hover {
      background: $black;
      color: $white;
    }

    @include breakpoint(small only) {
      width: rem-calc(40);
      margin: 0;
      font-size: $normal-size;
      line-height: rem-calc(40);
    }

    &-single {
      background: $black;
      color: $white;
      width: rem-calc(55);
      line-height: rem-calc(55);
      background: $black;
      color: $white;
      display: inline-block;
      font-size: $large-size;
      margin: 0 rem-calc(10);

      &:hover {
        background: $text;
        color: $white;
      }
    }
  }
}
