figure {
	position: relative;
	overflow: hidden;
  	height: rem-calc(550);
	width: 100%;
	background: $black;
	text-align: center;
	cursor: pointer;
  	margin: 0!important;

  &.height {
    height: rem-calc(350)!important;

    h2 {
      font-size: rem-calc(24);
			padding: 0 rem-calc(20);
    }

    @include breakpoint(medium down) {
      margin-bottom: rem-calc(25)!important;
			height: rem-calc(350);
    }
  }

	&.offer {
    height: rem-calc(250)!important;

    h2 {
      font-size: rem-calc(18);
			padding: 0 rem-calc(20)!important;
    }

		@include breakpoint(medium down) {
	    height: rem-calc(250);
	  }
  }

  @include breakpoint(medium down) {
    height: rem-calc(500);
  }
}

figure img {
	position: relative;
	display: block;
	margin: 0 auto;
	max-height: 100%;
	height: 100%;
	width: auto;
	opacity: 0.6;
}

figure figcaption {
	padding: 2em;
	color: $white;
	font-size: 1.25em;
	backface-visibility: hidden;
}

figure figcaption::before,
figure figcaption::after {
	pointer-events: none;
}

figure figcaption,
figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 55;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

figure h2 {
  font-size: $huge-size;
  @extend .big;

	&.room-title {
			@include breakpoint(small only) {
				font-size: $large-size;
			}
	}
}

figure h2 span {
	display: block;
  font-size: $small-size;
  color: $white;
  opacity: 1;
}

figure h2,
figure p {
	margin: 0;
  color: $white;
}

/* Romeo effect */
figure.effect-romeo {
	perspective: 1000px;
}

figure.effect-romeo img,
figure.effect-romeo .img {
	transition: opacity 0.35s, transform 0.35s;
	transform: translate3d(0,0,150px);
}

figure.effect-romeo .img {
	position: relative;
	@extend .photobg;
	height: 100%;
	z-index: 5;
	opacity: 0.6;
}

figure.effect-romeo:hover img,
figure.effect-romeo:hover .img {
	opacity: 0.6;
	transform: translate3d(0,0,0);
}

figure.effect-romeo figcaption::before,
figure.effect-romeo figcaption::after {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 40%;
	height: 1px;
	background: $white;
	content: '';
	transition: opacity 0.35s, transform 0.35s;
	transform: translate3d(-50%,-50%,0);
}

figure.effect-romeo:hover figcaption::before {
	opacity: 0.5;
	transform: translate3d(-50%,-50%,0) rotate(45deg);
}

figure.effect-romeo:hover figcaption::after {
	opacity: 0.5;
	transform: translate3d(-50%,-50%,0) rotate(-45deg);
}

figure.effect-romeo h2,
figure.effect-romeo p {
	position: absolute;
	top: 50%;
	left: 0;
  padding:0 rem-calc(65);
	width: 100%;
	transition: transform 0.35s;
}

figure.effect-romeo h2 {
	transform: translate3d(0,-50%,0) translate3d(0,-75%,0);
}
figure.effect-romeo:hover h2 {
	transform: translate3d(0,-50%,0) translate3d(0,-75%,0);
}

figure.effect-romeo p {
	transform: translate3d(0,-50%,0) translate3d(0,100%,0);

  @include breakpoint(medium down) {
    transform: translate3d(0,30%,0) translate3d(0,0%,0);
  }
}
figure.effect-romeo:hover p {
	transform: translate3d(0,-50%,0) translate3d(0,100%,0);
}
