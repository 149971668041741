#footer {
  position: relative;
  background: $black;

  p {
    font-size: $tiny-size;
    font-family: $header-font-family;
    margin: 0;
  }

  ul {
    @extend .clear-ul;

    li {
      display: inline;
      font-size: 0;

      &:first-child {
        a {
          &:before {
            display: none;
          }
        }
      }

      a {
        color: darken($white, 30%);
        display:inline;
        font-size: $small-size;
        position: relative;
        font-family: $header-font-family;

        &:before {
          content: "::";
          letter-spacing: rem-calc(3);
          font-size: $tiny-size;
          color: darken($white, 20%);
          padding: 0 rem-calc(10);
        }

        &:hover {
          color: $white;
        }
      }
    }
  }
}
