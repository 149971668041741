$error-color: #c53146;
$ok-color: #89b627;

.wpcf7-form-control-wrap.name:before{
    background:none;
}

#mc-embedded-subscribe {
  margin:1rem 0!important;
  top: 0px;
  clear: both;
}

.mce_inline_error,
#mce-error-response {
	font-size: $small-size;
	color: $error-color!important;
	background: none!important;
	border:rem-calc(1) solid $error-color!important;
	padding: rem-calc(10) rem-calc(15)!important;
	margin:1rem 0!important;
	border-radius: 0!important;
	top: 0px;
	clear: both;
}

input.mce_inline_error {
	font-size: $small-size;
	color: $error-color!important;
	background: none!important;
	border:rem-calc(1) solid $error-color!important;
	top: 0px;
	clear: both;
	margin:0 0 1rem 0!important;
}

#mce-success-response {
	font-size: $small-size;
	border-radius: 0!important;
	color: $ok-color!important;
	background: none!important;
	border:rem-calc(1) solid $ok-color!important;
	padding: rem-calc(10) rem-calc(15)!important;
	font-size: $small-size;
	margin:0 0 1rem 0!important;
	top: 0px;
	clear: both;
}

.screen-reader-response {
	display: none!important;
	top: 0px;
	clear: both;
	margin: 1rem 0 0 0;
}

.wpcf7-response-output {
	margin: 0!important;
}

.wpcf7-form-control-wrap {
	font-size: $small-size;
	text-transform: none;
	top: 0px;
	clear: both;
	margin:1rem 0!important;

	.wpcf7-not-valid-tip {
		font-size: $small-size;
		padding: rem-calc(10) rem-calc(15)!important;
		border-radius: 0!important;
		color: $error-color!important;
		background: none!important;
		border:rem-calc(1) solid $error-color!important;
		position: relative;
		display: block;
		top: 0px;
		clear: both;
		margin:0 0 1rem 0!important;
	}
}

.wpcf7-validation-errors {
	font-size: $small-size;
	padding: rem-calc(10) rem-calc(15)!important;
	border-radius: 0!important;
	color: $error-color!important;
	background: none!important;
	border:rem-calc(1) solid $error-color!important;
	display: block;
	top: 0px;
	clear: both;
	margin:1rem 0.9rem!important;
}

.wpcf7-mail-sent-ok {
	font-size: $small-size;
	padding: rem-calc(10) rem-calc(15)!important;
	border-radius: 0!important;
	color: $ok-color!important;
	background: none!important;
	border:rem-calc(1) solid $ok-color!important;
	display: block;
	top: 0px;
	clear: both;
	margin:1rem 0!important;
}

span.wpcf7-list-item {
	margin-left: 0!important;
}
