$error-color: #c53146;
$ok-color: #89b627;
$input-height: rem-calc(46);

form {
	position: relative;

	small {
		font-size:$tiny-size;
	}

	label {
		display: block;
		margin-bottom: 0.5rem;
		font-weight: 300;
		font-size:$normal-size;
		color: $text;
		line-height: normal;

		&.small {
			font-size:$small-size;
			font-weight: 300;
			text-transform: none;
			line-height: normal;
			cursor: pointer;
		}
	}

	input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea, select {
		background-color: $white;
		height: $input-height;
		border:none;
		border-bottom: rem-calc(2) solid darken($light, 5%);
		padding: 0.5rem 1rem;
		color: $text;
		font-weight: 300;
		outline: none!important;
		box-shadow:none;
		font: {
			size:$normal-size;
			family: $body-font-family;
		}

		&:focus {
			border:none;
			border-bottom: rem-calc(2) solid $black;
			background-color: $white;
			box-shadow:none;
			outline: none!important;
		}

		&.wpcf7-not-valid {
			border-bottom: rem-calc(2) solid $error-color;
		}
	}

	input[type="checkbox"] {
		vertical-align: middle;
		margin: 0 rem-calc(8) 0 0!important;
	}

	textarea {
		height: auto!important;
		min-height: rem-calc(200);
		font-weight: 300;
		font: {
			size:$normal-size!important;
			family: $body-font-family;
		}
		overflow: auto;
	}

	input[type="submit"] {
		background-color: $black;
		border:none;
		padding:0 30px;
		height: $input-height;
		color: $white;
		font-weight: 400;
		@extend .animacja;
		outline: none!important;
		font: {
			size:$normal-size;
			family: $header-font-family;
		}

		&:hover {
			background-color:$text;
		}
	}

	::-webkit-input-placeholder {
		color: rgba($text,1);
	}
	:-moz-placeholder {
		color: $text;
		opacity: 1;
	}
	::-moz-placeholder {
		color: $text;
		opacity:  1;
	}
	:-ms-input-placeholder {
		color: rgba($text, 1);
	}
}


.picker {
	position: relative!important;
}

input[readonly] {
     cursor: pointer;
}

#checkout {
	position: relative;
}

.form-wrapper {
	display: table;
	table-layout: fixed;
	width: 100vw;
	height: 100vh;
	padding: 0;
	background: $light!important;

	.inner {
		display: table-cell;
		width: 100%;
		height: 100%;
		vertical-align: middle;
	}

	h1 {
		font-weight: 800;
		font-size: rem-calc(22);
		margin-bottom: rem-calc(7);
	}
}

body.formularz {
	height: auto!important;
}

body.admin-bar {
	.form-wrapper {
		height: calc(100vh - 32px);
	}
}

.m-signature-pad--footer {
	position: relative;
	button {
		@include breakpoint(small only) {
			margin-bottom: rem-calc(16);
			position: absolute;
			top: 0;
			right: 0;
		}
	}
}

.m-signature-pad--body {
    position: relative;
	display: block;
	line-height: normal;

	canvas {
		width: 100%;
		height: 120px;
		border-bottom: rem-calc(2) solid darken($light, 5%);
		background-color: $white;
		margin-bottom: rem-calc(16);
		display: block;
	}
}

.chosen-container-single {
	.chosen-single {
		background: $white!important;
		height: $input-height;
		line-height: $input-height;
		border:none;
		border-bottom: rem-calc(2) solid darken($light, 5%);
		padding: 0rem 1rem;
		color: $text;
		border-radius: 0px!important;
		font-weight: 300;
		outline: none!important;
		box-shadow:none;
		font: {
			size: $normal-size;
			family: $body-font-family;
		}
	}

	&:after {
		content: "";
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: rem-calc(15);
		z-index: 20;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 7px 5px 0 5px;
		border-color: $black transparent transparent transparent;
	}
}

.chosen-drop ul {
	li {
		&:before {
			display: none!important;
		}

		&.active-result {

		}

		&.result-selected,
		&.highlighted {
			background: $black!important;
			color: $white!important;
		}
	}
}
