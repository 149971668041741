#header {
  @extend .photobg;
  @include bgcover;
  @extend .height100vh;

  &:before {
    @extend .before-absolute;
    background-color: rgba($black, 0.6);
    box-shadow: inset 0 0 4rem rgba($black, 0.8);
  }

	.videobg {
		transform: translateX(-50%) translateY(-50%);
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		overflow: hidden;
		z-index: -1;

		video {
			min-width: 100%;
			min-height: 100%;
			width: auto;
			height: auto;
			z-index: -100;
		}
	}

  .header-text {
    position: relative;
    z-index: 50;

    p {
      color: $white;

      &#breadcrumbs {
        @extend .big;
        letter-spacing: rem-calc(3);
        font-size: $small-size;

        a {
          border-bottom: none!important;

          &:hover {
            color: rgba($white, 0.5);
          }
        }
      }
    }

    a {
      color: $white;
      border-bottom: rem-calc(1) solid $white;

      &:hover {
        border-bottom: rem-calc(1) solid rgba($white, 0.5);
      }
    }

    h1 {
      color: $white;
      font-size: rem-calc(51);

      span {
        display: block;
        font-size: rem-calc(22);
        font-weight: 400;
      }
    }
  }
}

.right-nav {
  transform: translateY(-50%);
  top: 50%;
  right: 0;
  position: fixed;
  @extend .animacja;
  z-index: 666;

  @include breakpoint(medium down) {
    top: 0;
	transform: none;
  }
}

.hostels {
  position: absolute;
  top: rem-calc(15);
  right: rem-calc(15);
  color: $white;
  text-align: right;
  @extend .big;
  z-index: 150;
  font: {
    family: $header-font-family;
    size: $tiny-size;
  }

  a {
    font-weight: 700;
    color: $white;

    &:hover {
      color: rgba($white, 0.7)
    }
  }

  span {
    display: inline-block;
    padding: 0 rem-calc(15);
  }

  @include breakpoint(medium down) {
    display: none;
  }
}

.app {
  width: rem-calc(104);
  height: rem-calc(104);
  vertical-align: middle;
  background: $black;
  text-align: center;
  color: $white;
  @extend .big;
  padding-top: rem-calc(20);
  display: block;
  @extend .animacja;
  line-height: normal;
  font: {
    family: $header-font-family;
    size:$tiny-size;
    weight: 300;
  }

  @include breakpoint(medium down) {
    display: none;
  }

  &:hover {
    background-color: $text;
  }

  span {
    display: block;
    font: {
      weight: 700;
      size:rem-calc(19);
    }
  }

  a {
    color: $white;
  }

  .fa {
    display: block;
    font-size: $large-size;
  }
}

.booking {
  width: rem-calc(104);
  height: rem-calc(104);
  background: $black;
  text-align: center;
  color: $white;
  @extend .big;
  padding-top: rem-calc(15);
  display: block;
  @extend .animacja;
  font: {
    family: $header-font-family;
    size:$tiny-size;
    weight: 700;
  }

  &:hover {
    background-color: $text;
  }

  a {
    color: $white;
  }

  img {
    height: rem-calc(50);
    display: block;
    margin:0 auto rem-calc(10) auto;
  }

  @include breakpoint(medium down) {
	  width: rem-calc(80);
      height: rem-calc(80);
	  padding-top: rem-calc(17);
	  font-size: rem-calc(11);
	  font-weight: 400;

	  img {
		  height: rem-calc(25);
	  }
  }
}

.mobilemenu {
  position: fixed;
	width: 100%;
	height: 100%;
	z-index: 180;
	background: $white;
	text-align: center;
	padding: rem-calc(150) 0 rem-calc(50) 0;
	display: none;
  background: rgba($black, 0.95);

  @include breakpoint(medium down) {
    padding: rem-calc(100) 0 rem-calc(50) 0;
  }

  .inner {
		overflow: auto;
		width: 100%;
		height: 100%;
	}

  ol {
	list-style-position:inside;
    list-style-type: decimal-leading-zero;
    margin:0 0 rem-calc(30) 0;

    li {
      font-size: $small-size;
      font-family: $header-font-family;
      padding: rem-calc(5) 0;
	  color: darken($white, 0.7);
	  font-weight: 400;

      a {
        color: $white;
        font-size: $large-size;
		display: inline-block;
		padding: 0 0 0 rem-calc(10);
        font-weight: 700;

        @include breakpoint(small only) {
          font-size: $normal-size;
        }
      }
    }
  }
}

.logo {
  background-color: $black;
  line-height: rem-calc(104);
  display: block;
  float: left;
  @extend .animacja;

  @include breakpoint(medium down) {
    line-height: rem-calc(80);

    img {
      height: rem-calc(40)!important;
      vertical-align: middle;
    }
  }

  a {
    padding: 0 rem-calc(30);
    display: block;
  }

  img {
    height: rem-calc(60);
    vertical-align: middle;
  }
}

.logo-nav {
  top: 50%;
  left: 0;
  position: absolute;
  @extend .animacja;
  z-index: 200;
  transform: translateY(-50%);
  backface-visibility: hidden;

  &.fixed {
    position: fixed;
    top: 0;
    transform:none;
  }

  @include breakpoint(medium down) {
    top: 0;
    transform:none;
    position: fixed;
  }
}

.menu-open {
  height: rem-calc(104);
  background-color: $white;
  width: rem-calc(104);
  color: $black;
  text-align: center;
  display:block;
  float: left;
  @extend .animacja;
  cursor: pointer;
  position: relative;

  &:hover {
    background: $black;
    color: $white;

    span {
      background: $white;
    }
  }

  @include breakpoint(medium down) {
    height: rem-calc(80);
    width: rem-calc(80);
    font-size: rem-calc(30);
  }
}

.mobilemenu-btn-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.mobilemenu-btn {
  width: 40px;
  height: 44px;
  position: relative;
  margin: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: $black;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 8px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 18px;
    }

    &:nth-child(4) {
      top: 28px;
    }
  }

  &.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  &.open span:nth-child(2) {
    transform: rotate(45deg);
  }

  &.open span:nth-child(3) {
    transform: rotate(-45deg);
  }

  &.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
}

.langs {
  color: darken($white, 65%);

  span {
    padding: 0 rem-calc(20);
    display: inline-block;
  }

  a {
    color: darken($white, 20%);

    &.active {
      color: $white;
      font-weight: 700;
    }

    &:hover {
      color: $white;
    }
  }
}
