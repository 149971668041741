$huge-size: rem-calc(35);
$large-size: rem-calc(24);
$normal-size: rem-calc(18);
$small-size: rem-calc(14);
$tiny-size: rem-calc(12);

body {
	font: {
		size: $normal-size;
		family: $body-font-family;
	}
	color: $text;
}

p, td, li, button, input, textarea {
	color: $text;
	font: {
		size: $normal-size;
		family: $body-font-family;
		weight: 300;
	}

	small {
		font-size: 81%;
	}

	strong {
		font-weight: 700;
	}
}

.text-content {
	a {
		color: $black;
		border-bottom: rem-calc(1) solid lighten($black, 30%);

		&:hover {
			color: $text;
			border-bottom: rem-calc(1) solid $black;
		}

		&.noborder {
			border-bottom: none!important;
		}
	}

	strong {
		color: $black;
	}

	ul {
		margin: 0 0 rem-calc(16) 0;
		list-style-type: none;
		padding: 0;

		li {
			position: relative;
			padding: 0 0 0 rem-calc(30);

			&:before {
				content: "\f192";
				position: absolute;
				top: rem-calc(4);
				left: 0;
				color: $black;
				font-family: "FontAwesome";
				font-size: $small-size;
			}
		}
	}
}

h1, h2, h3, h4, h5, h6 {
	font: {
		family:  $header-font-family;
		weight: 900;
	}
	@extend .margin-bottom50;
	@extend .big;
	color: $black;
	line-height: normal;

	strong {
		font: {
			weight: 900;
		}
	}

	&.thin {
		font: {
			weight: 300;
		}
	}

	&.large {
		font-size: 200%;
	}
}

h1 {
	font-size: rem-calc(34);

	@include breakpoint(medium only) {
		font-size: rem-calc(34);
	}

	@include breakpoint(small only) {
		font-size: rem-calc(30);
	}
}

h2 {
	font-size: rem-calc(30);

	@include breakpoint(medium only) {
		font-size: rem-calc(30);
	}

	@include breakpoint(small only) {
		font-size: rem-calc(28);
	}
}

h3 {
	font-size: rem-calc(28);

	@include breakpoint(medium only) {
		font-size: rem-calc(28);
	}

	@include breakpoint(small only) {
		font-size: rem-calc(26);
	}
}

h4 {
	font-size: rem-calc(26);

	@include breakpoint(medium only) {
		font-size: rem-calc(26);
	}

	@include breakpoint(small only) {
		font-size: rem-calc(24);
	}
}

h5 {
	font-size: rem-calc(22);

	@include breakpoint(medium only) {
		font-size: rem-calc(22);
	}

	@include breakpoint(small only) {
		font-size: rem-calc(20);
	}
}

h6 {
	font-size: rem-calc(20);

	@include breakpoint(medium only) {
		font-size: rem-calc(20);
	}

	@include breakpoint(small only) {
		font-size: rem-calc(18);
	}
}
