$white: #ffffff;
.white {
	color: $white!important;
}

$black: #2a2a2a;
.black {
	color: $black!important;
}

$text: #6c6c6c;
.text {
	color: $text!important;
}

$light: #f8f8f8;
.light {
	color: $light!important;
}

a {
	&.white {
		color: $white;

		&:hover {
			color: rgba($white, 0.7);
		}
	}

	&.black {
		color: $black;

		&:hover {
			color: $text;
		}
	}

	&.text {
		color: $text;

		&:hover {
			color: $black;
		}
	}

	&.light {
		color: $light;

		&:hover {
			color: $text;
		}
	}
}
