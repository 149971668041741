.button {
  @extend .big;
  margin: 0;
  padding: rem-calc(18) rem-calc(25);
  font: {
    family: $header-font-family;
    size: $small-size;
  }

  &.nohover {
    cursor: default;
  }

  &.small {
	  padding: rem-calc(10) rem-calc(10);
  }

  &.large {
    padding: rem-calc(20) rem-calc(30);
    font-size: $normal-size;
  }

  &.black {
    background-color: $black;
    border: rem-calc(1) solid $black;
    color: $white!important;

    &:not(.nohover):hover {
      color: $black!important;
      background-color:transparent;
      border: rem-calc(1) solid transparent;
    }

    &.border {
      background-color:transparent!important;
      border: rem-calc(1) solid $black;
      color: $black!important;

      &:not(.nohover):hover {
        background-color: $black!important;
        color: $white!important;
        border: rem-calc(1) solid $black;
      }

	  &:focus {
		  background-color:transparent!important;
	      border: rem-calc(1) solid $black;
	      color: $black!important;
	  }
    }
  }

  &.white {
    background-color: $white;
    border: rem-calc(1) solid $white;
    color: $black!important;

    &:not(.nohover):hover {
      color: $white!important;
      background-color: $black;
      border: rem-calc(1) solid $black;
    }

    &.border {
      background-color:transparent!important;
      border: rem-calc(1) solid $white;
      color: $white!important;

      &:not(.nohover):hover {
        background-color: $white!important;
        color: $black!important;
        border: rem-calc(1) solid $white;
      }
    }
  }

  .fa-angle-right {
    position: relative;
    animation: anglemove 0.5s infinite;
  }
}

.room-slide {
	.button {
		&.black {
			&:hover {
				background: $white!important;
				border: rem-calc(1) solid $black!important;
			}
		}
	}
}

@keyframes anglemove {
  0% {
    right: rem-calc(0);
  }
  50% {
    right: rem-calc(-5);
  }
  100% {
    right: rem-calc(0);
  }
}
