.offer-content {
  padding: rem-calc(25);
  border-bottom: rem-calc(1) solid $black;
  position: relative;

  @include breakpoint(medium down) {
    margin-bottom: rem-calc(50);

    &:last-child {
      margin-bottom:0;
    }
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &:before {
    content: "";
    width: rem-calc(20);
    height: rem-calc(1);
    background: $black;
    transform: rotate(-45deg);
		animation:broke-line-move 5s infinite;
    position: absolute;
    bottom:-1px;
    right: rem-calc(20);
    z-index: 50;
  }

  &:after {
    content: "";
    width: rem-calc(20);
    height: rem-calc(20);
    background: $white;
    position: absolute;
    bottom:-10px;
    right: rem-calc(20);
    z-index: 30;
  }
}

.tabs-panel {
  padding: 0;
  border:none;
  border-top: rem-calc(2) solid $black;
}

.tabs-content {
  border:none;
}

ul.tabs {
  border: none;
  text-align: center;

  li {
    float: none;
    display: inline-block;
    @extend .big;
    font-weight: 400;

    @include breakpoint(small only) {
      display: block;
    }

    &.is-active {
        a {
          color: $white;
          background: $black;
        }
    }

    a {
      font-size: $normal-size;
      color: $black;

      @include breakpoint(small only) {
        display: block;
      }
    }
  }
}

.przyjaciel {
  height: rem-calc(268);
  border:rem-calc(1) solid $black;
  @extend .animacja;

  a {
    border-bottom: none!important;
  }

  img {
      max-height: rem-calc(150);
      height: auto;
      max-width: 80%;
      vertical-align: middle;
      filter: grayscale(100%);
      opacity: 0.5;
      @extend .animacja;
  }

  &:hover {
    img {
      filter: grayscale(0%);
      opacity: 1;
    }
  }
}
