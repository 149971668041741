@mixin transition($transition-property, $transition-time, $method) {
    transition: $transition-property $transition-time $method;
}

@mixin gradient($fromColor, $toColor) {
	background: $toColor; /* Fallback Color */
	background: linear-gradient(to bottom, $fromColor, $toColor);
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
}

@mixin gradient-skos($fromColor, $toColor) {
	background: $toColor; /* Fallback Color */
	background: linear-gradient(-45deg, $fromColor, $toColor);
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1,StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
}

@mixin horizontal($fromColor, $toColor) {
	background: $toColor;
	background: linear-gradient(to right, $fromColor,$toColor);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$fromColor}', endColorstr='#{$toColor}',GradientType=1 );
}

@mixin bgcover {
	background-size: cover!important;
}

@mixin bgcontain {
	background-size: contain!important;
}