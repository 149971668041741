.rooms-slider {

	.gallery-cell {
		width: 60%;
		opacity: 0.3;

		&.is-selected {
			opacity: 1;
		}

		@include breakpoint(medium only) {
			width: 80%;
		}

		@include breakpoint(small only) {
			width: 100%;
		}
	}

	.room-slide {
		margin: 0 rem-calc(25);
		position: relative;
		padding: 0 0 rem-calc(35) 0;

		@include breakpoint(small only) {
			margin: 0;
		}

		.button {
			position: absolute;
			z-index: 100;
			bottom: rem-calc(0);
			right: rem-calc(20);
			margin: 0;
		}
	}

	.flickity-prev-next-button {
	  position: absolute;
	  top: 50%;
	  width:rem-calc(60);
	  height: rem-calc(60);
	  border: none;
	  border-radius:0!important;
	  background:$white!important;
	  padding:0!important;
	  cursor: pointer;
  	transform: translateY(-50%);

		@include breakpoint(small only) {
			width:rem-calc(40);
		  height: rem-calc(40);
		}
	}

	.flickity-prev-next-button.previous {
		left: 0px;
	}

	.flickity-prev-next-button.next {
		right: 0px;
	}

	.flickity-prev-next-button .arrow {
	  fill: $black;
	}

	/* color & size if no SVG - IE8 and Android 2.3 */
	.flickity-prev-next-button.no-svg {
	  color: $black;
	}
}

.photos-slider {
	.gallery-cell {
		width: 60%;
		opacity: 0.3;

		&.is-selected {
			opacity: 1;
		}

		@include breakpoint(medium only) {
			width: 80%;
		}

		@include breakpoint(small only) {
			width: 100%;
		}
	}

	.photo-slide {
		margin: 0 rem-calc(25);
		position: relative;
		padding: 0 0 rem-calc(35) 0;

		@include breakpoint(small only) {
			margin: 0;
		}

		.all {
			position: relative;
			z-index: 50;
			width: 100%;
			height: 100%;
			display: block;
			overflow: hidden;

			&:hover {
				background:rgba($black, 0.4);
				&:before {
					transform: translateX(-50%) translateY(-50%);
					opacity: 1;
				}
			}

			&:before {
				position: absolute;
				top: 50%;
				left: 50%;
				content: "\f00e";
				font-family: "FontAwesome";
				width: rem-calc(60);
				line-height: rem-calc(60);
				color: $white;
				background: $black;
				text-align: center;
				font-size: $large-size;
				@extend .animacja;
				opacity: 0;
				transform: translateX(-50%) translateY(-150%);
			}
		}

		.photobg {
			height: rem-calc(500);

			@include breakpoint(medium down) {
		    height: rem-calc(350);
		  }
		}

		span {
			position: absolute;
			z-index: 100;
			bottom: rem-calc(0);
			left: 50%;
			width: 80%;
			background: $black;
			color: $white;
			font-size: $small-size;
			padding: rem-calc(15) rem-calc(30);
			text-align: center;
			transform: translateX(-50%);
			margin: 0;
		}
	}

	.flickity-prev-next-button {
	  position: absolute;
	  top: 50%;
	  width:rem-calc(60);
	  height: rem-calc(60);
	  border: none;
	  border-radius:0!important;
	  background:$white!important;
	  padding:0!important;
	  cursor: pointer;
  	transform: translateY(-50%);

		@include breakpoint(small only) {
			width:rem-calc(40);
		  height: rem-calc(40);
		}
	}

	.flickity-prev-next-button.previous {
		left: 0px;
	}

	.flickity-prev-next-button.next {
		right: 0px;
	}

	.flickity-prev-next-button .arrow {
	  fill: $black;
	}

	/* color & size if no SVG - IE8 and Android 2.3 */
	.flickity-prev-next-button.no-svg {
	  color: $black;
	}
}
